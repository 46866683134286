import React, { Component } from "react"
import Link from "gatsby-link"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Cathero from "../components/Category-hero"
import "../css/Blogs.css"
import parser from "react-html-parser"
class PostsTemplate extends Component {
  render() {
    const data = this.props.data
    return (
      <>
        <Layout>
          <div className="container-fluid mainsecblock" id="block">
            <div className="row ">
              <div className="col-lg-1"></div>
              <div className="col-lg-4 col-md-12  d-flex justify-content-center flex-column hero-cont ">
                <div className="title">
                  <h1 className="heading text-center">Blogs</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-12  d-flex justify-content-center flex-column hero-img">
                <picture>
                  <source
                    media="(max-width:768px)"
                    srcset="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-318.png"
                  />

                  <source
                    media="(max-width:480px)"
                    srcset="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-318.png"
                  />

                  <img
                    src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-317-1.png"
                    alt="blog"
                    className="hero-landingsrc"
                  />
                </picture>
              </div>

              <div className="col-lg-1"></div>
            </div>
          </div>
          <section className="blogposts" id="blog">
            <div className="container" id="blogposts">
              <div className="row blogmain">
                {data.allWordpressPost.edges.map(({ node }, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <div className="rightblog" id={"demo" + index}>
                      <div className="col-lg-10 col-sm-11 col-xs-11 card blogcard">
                        {node.featured_media && (
                          <img
                            className="card-img-top blogcardimg"
                            src={
                              node.featured_media.localFile.childImageSharp
                                .fluid.originalImg
                            }
                            alt="blog image"
                          />
                        )}
                        <div className="card-body p-0 blockbody">
                          <h5 className="card-title blocktitle">
                            <Link to={node.slug} style={{ color: "#212529" }}>
                              {node.title}{" "}
                            </Link>
                          </h5>
                          <p className="card-text blog-text text-left">
                            {" "}
                            <div
                              className={"post-content"}
                              dangerouslySetInnerHTML={{ __html: node.excerpt }}
                            />
                          </p>
                          <Link to={node.slug} className="readmore">
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <hr className="col-lg-10 col-md-10 col-sm-12 m-2 mt-4 verticalline" />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

PostsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostsTemplate

export const pageQuery = graphql`
  query postsQuery {
    allWordpressPost {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`
